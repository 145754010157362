import React from 'react'
import SbEditable from 'storyblok-react'
import Icon from '@system/icon'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  phil: {
    fill: (props) => !!props.blok.color && props.blok.color.color,
  },
}))

const SbFieldIconPickerConvert = (props) => {
  const classes = useStyles(props)
  return (
    <SbEditable content={props.blok}>
      <Icon styles={classes.phil}>{props.blok.icon}</Icon>
    </SbEditable>
  )
}
export default SbFieldIconPickerConvert
